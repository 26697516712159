"use client";

import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Info, AlertTriangle, CheckCircle2, AlertCircle, XCircle } from "lucide-react";

import { cn } from "@/lib/utils";

export type AlertVariantProps = VariantProps<typeof alertVariants>;

type AlertProps = {
	hasIcon?: boolean;
	allowDismiss?: boolean;
	id?: string;
} & React.HTMLAttributes<HTMLDivElement> &
	VariantProps<typeof alertVariants>;

const AlertIcons = {
	notification: Info,
	"new-info": Info,
	warning: AlertTriangle,
	success: CheckCircle2,
	error: AlertCircle,
} as const;

const alertVariants = cva(
  "group relative rounded-lg border-l-8 py-4 pl-7 pr-9 text-gray-900",
  {
    variants: {
      variant: {
        default: "text-examine-green-900 border-examine-green-400 [&_.lucide-xcircle]:fill-examine-green-400 [&_.lucide-xcircle]:stroke-white",
        general: "general bg-examine-purple-100 border-examine-purple-300 [&_.lucide-xcircle]:fill-examine-purple-300 [&_.lucide-xcircle]:stroke-examine-purple-100",
        summary: "summary bg-gray-100 border-examine-purple-300 [&_.lucide-xcircle]:fill-examine-purple-300 [&_.lucide-xcircle]:stroke-gray-100",
        quotation:
          "quotation border-l-4 border-info py-2 pl-4 text-primary xl:pl-6 xl:text-xl [&_.lucide-xcircle]:fill-info [&_.lucide-xcircle]:stroke-white",
        digging: "digging border-examine-green-400 bg-[#ebfcff] [&_.lucide-xcircle]:fill-examine-green-400 [&_.lucide-xcircle]:stroke-[#ebfcff]",
        dd: "digging border-examine-green-400 bg-[#ebfcff] [&_.lucide-xcircle]:fill-examine-green-400 [&_.lucide-xcircle]:stroke-[#ebfcff]",
        caution: "caution bg-caution-bg border-caution-border [&_.lucide-xcircle]:fill-caution-border [&_.lucide-xcircle]:stroke-caution-bg",
        tip: "tip bg-[#ffe6b9] border-[#f2ce72] text-[#222] [&_.lucide-xcircle]:fill-[#f2ce72] [&_.lucide-xcircle]:stroke-[#ffe6b9]",
        premium: "premium bg-main border-[#fde047] [&_.lucide-xcircle]:fill-[#fde047] [&_.lucide-xcircle]:stroke-main",
        warning: "border-[#F2CE72] bg-[#F1CD7833] [&_.lucide-xcircle]:fill-[#F2CE72] [&_.lucide-xcircle]:stroke-[#FFF2BE]",
        info: "border-examine-purple-500 bg-examine-purple-100 [&_.lucide-xcircle]:fill-examine-purple-500 [&_.lucide-xcircle]:stroke-examine-purple-100",
				success: "bg-examine-teal-100 border-examine-teal-500 text-examine-teal-900",
      },
			style: {
				default: "",
				// alternative style: https://www.figma.com/file/p0x0PyuO0SmY0uTjBpG3CA/Examine-Design-Files---Master-(updated---2024-01)?type=design&node-id=1746-65466&mode=design&t=BBVgkttblwOka0qv-4
				alt: "flex items-start p-4 text-sm lg:text-base rounded border text-[--text-color] border-[--border-color] bg-[--bg-color]"
			}
    },
		compoundVariants: [
			{
				variant: "info",
				style: "alt",
				class: "[--text-color:#084298] [--border-color:#b6d4fe] [--bg-color:#cfe2ff]",
			},
			{
				variant: "warning",
				style: "alt",
				class: "[--text-color:#664d03] [--border-color:#FFECB5] [--bg-color:#FFF3CD]",
			},
			{
				variant: "success",
				style: "alt",
        class: "[--text-color:#0F5132] [--border-color:#BADBCC] [--bg-color:#D1E7DD]",
				// class: "[--text-color:#0D503C] [--border-color:#4DD4AC] [--bg-color:#D2F4EA]",
			},
			{
				variant: "caution",
				style: "alt",
        class: "[--text-color:#842029] [--border-color:#F5C2C7] [--bg-color:#F8D7DA]",
			}
		],
    defaultVariants: {
      variant: "default",
			style: "default"
    },
  }
);

const shouldShowAlert = (id: string) => {
	if (!id) {
		return true;
	}

	const seen = localStorage.getItem(id);
	if (seen) {
		return false;
	}
	return true;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
	({ className, variant, style, hasIcon, children, allowDismiss, id, ...props }, ref) => {
		const Icon = AlertIcons[variant] ?? "new-info";
		const [show, setShow] = React.useState(false);

		React.useEffect(() => {
			setShow(shouldShowAlert(id));
		}, [id]);

		const onClose = (event) => {
			event.preventDefault();
			if (id) {
				localStorage.setItem(id, "seen");
			}
			setShow(false);
		};

		return (
			<>
				{show && (
					<div
						ref={ref}
						role="alert"
						className={cn(alertVariants({ variant, style }), className)}
						id={id}
						{...props}
					>
						{allowDismiss && (
							<button onClick={onClose} className="absolute right-2 top-2">
								<XCircle className="size-5 hover:opacity-70" />
							</button>
						)}
						{hasIcon && (
							<span className="mr-2">
								<Icon className="size-5 shrink-0 fill-current stroke-[var(--bg-color)]" />
							</span>
						)}
						{children}
					</div>
				)}
			</>
		);
	}
);
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLHeadingElement> & { as?: any }
>(({ className, as: Comp = "h5", ...props }, ref) => (
	<Comp
		ref={ref}
		className={cn(
			"mb-1 text-xl font-medium group-[.caution]:text-caution-text group-[.digging]:text-primary group-[.tip]:text-[#a04300]",
			className
		)}
		{...props}
	/>
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => <div ref={ref} className={cn("", className)} {...props} />);
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription };
